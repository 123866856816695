import {
    ArrowUUpLeft,
    Bank,
    ClipboardText,
    DotsThree,
    File,
    Link,
    PaperPlaneTilt,
    PlusCircle,
    Prohibit,
    WhatsappLogo,
} from '@phosphor-icons/react'
import { Dropdown, Popconfirm, Row, message } from 'antd'
import { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingDefinition } from '../../../interfaces/loadingDef'
import EmailActions from './SingleActions/EmailActions'
import CopyAction from './SingleActions/CopyAction'
import { openModal, setElementSelected } from '../../context/modalsSlice'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { useAuth } from 'reactfire'
import { ShareViaWhatsapp } from '../../functions/helpers'
import { usePermissions } from '../../customHooks/usePermissions'
import { setData } from '../../context/dataSlice'
import { MenuProps } from 'antd/lib'
import Typography from 'antd/es/typography/Typography'
import { clientDataHelper } from '../../../helpers/helperFunctions'

interface PaymentActionsProps {
    payment: any
    enabled?: boolean | null
    hide?: boolean | null
}

const PaymentsActions: FunctionComponent<PaymentActionsProps> = (props) => {
    const dispatch = useDispatch()

    const { canCreatePayment } = usePermissions()
    const { clients } = useSelector((state: any) => state.data)

    const [loading, setLoading] = useState<LoadingDefinition[]>([])
    const [, /*actionOnPaymen*/ setactionOnPayment] = useState(null)
    const auth = useAuth()
    const { payment } = props

    if (props.hide) return <></>

    const thisPaymentHasAnyLoading = loading.find((l) => l.id === payment?.id) !== undefined

    // const ShareForWhatsApp = () => {
    //     return (
    //         <div
    //             style={{
    //                 cursor: 'pointer',
    //                 marginLeft: '5px',
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 alignItems: 'center',
    //                 justifyContent: 'center',
    //             }}
    //         >
    //             <WhatsappLogo
    //                 size={18}
    //                 weight="regular"
    //                 className="icon"
    //                 onClick={() =>
    //                     ShareViaWhatsapp(
    //                         `Puedes realizar el pago por ${payment.amount} ${payment.currency} en el siguiente enlace! 😉 : ${payment.shortURL}`,
    //                     )
    //                 }
    //             />
    //         </div>
    //     )
    // }

    // const IssueAnInvoice = () => {
    //     return (
    //         <div
    //             style={{
    //                 cursor: 'pointer',
    //                 marginLeft: '5px',
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 alignItems: 'center',
    //                 justifyContent: 'center',
    //             }}
    //         >
    //             <Tooltip title="Emitir factura">
    //                 <PlusCircle
    //                     size={18}
    //                     weight="regular"
    //                     className="icon"
    //                     onClick={() => {
    //                         const ctxClient = clients.find(
    //                             (c: any) =>
    //                                 c.id === payment?.client?.id ||
    //                                 c.id === payment?.clientId ||
    //                                 c.id === payment?.clientID,
    //                         )

    //                         dispatch(
    //                             setData({
    //                                 item: 'client',
    //                                 data: ctxClient || payment?.client,
    //                             }),
    //                         )
    //                         dispatch(
    //                             setData({
    //                                 item: 'selectedServices',
    //                                 data: payment?.items,
    //                             }),
    //                         )
    //                         dispatch(
    //                             setData({
    //                                 item: 'invoice',
    //                                 data: {
    //                                     payments: [payment?.id],
    //                                     paymentForm: payment?.payment_form || payment.paymentForm,
    //                                     paymentMethod: payment?.payment_method || payment.paymentMethod,
    //                                     currency: payment?.currency,
    //                                     exchange_rate: payment?.exchange_rate || payment.exchangeRate || 1,
    //                                 },
    //                             }),
    //                         )

    //                         dispatch(openModal('invoiceModalVisible'))
    //                     }}
    //                 />
    //             </Tooltip>
    //         </div>
    //     )
    // }

    const CancelAction = () => {
        if (payment?.status !== 'canceled' && payment?.cancellation_status !== 'pending' && canCreatePayment) {
            return (
                <div
                    style={{
                        cursor: 'pointer',
                        marginLeft: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Popconfirm
                        disabled={thisPaymentHasAnyLoading}
                        title="Cancelar pago"
                        description="Este recibo no se podrá pagar"
                        okText="Cancelar"
                        cancelText="Volver"
                        okButtonProps={{ danger: true }}
                        cancelButtonProps={{ type: 'default' }}
                        onConfirm={async () => {
                            setactionOnPayment(payment.id)
                            message.loading('Cancelando pago...')
                            try {
                                const resp = await SignedInternalAPIRequest(
                                    {
                                        type: 'payment_request_cancel',
                                        id: payment.paymentIntent,
                                        paymentID: payment.id,
                                        fid: payment.fid,
                                    },
                                    'paymentsHandler',
                                    auth.currentUser,
                                )

                                if (resp.error) {
                                    message.error('Error al cancelar pago')
                                    return setactionOnPayment(null)
                                }
                                message.success('Pago cancelado')
                                setactionOnPayment(null)
                            } catch (error) {
                                message.error('Error al cancelar pago')
                                setactionOnPayment(null)
                            }
                            await new Promise((resolve) => setTimeout(resolve, 1000))
                            setLoading(loading.filter((l) => l.id !== payment?.id && l.action !== `cancel`))
                        }}
                    >
                        <Typography> Cancelar pago</Typography>
                    </Popconfirm>
                </div>
            )
        } else return <></>
    }

    // const ToReview = () => {
    //     if (payment?.status === 'review_requested') {
    //         return (
    //             <>
    //                 <Tooltip title="Revisar pago">
    //                     <File
    //                         size={18}
    //                         weight="regular"
    //                         style={{ marginLeft: '5px' }}
    //                         onClick={() => {
    //                             dispatch(
    //                                 setData({
    //                                     item: 'payment',
    //                                     data: payment,
    //                                 }),
    //                             )
    //                             dispatch(openModal('approveModalVisible'))
    //                         }}
    //                         className="icon clickable"
    //                     />
    //                 </Tooltip>
    //             </>
    //         )
    //     } else return <></>
    // }

    const PaymentSucceededToPending = () => {
        const title = 'Recursos Relacionados'

        dispatch(
            setData({
                item: 'relationInfo',
                data: {
                    title,
                    sourceType: 'payments',
                    item: payment,
                },
            }),
        )

        dispatch(openModal('cancelResourcesModalVisible'))
    }

    // const DisplayBankTransferInfo = () => {
    //     return (
    //         <Tooltip title="Ver instrucciones de transferencia">
    //             <Bank
    //                 size={18}
    //                 weight="regular"
    //                 style={{ marginLeft: '5px' }}
    //                 onClick={() => {
    //                     dispatch(
    //                         setData({
    //                             item: 'payment',
    //                             data: payment,
    //                         }),
    //                     )
    //                     dispatch(openModal('stripeBankInstructionsVisible'))
    //                 }}
    //                 className="icon clickable"
    //             />
    //         </Tooltip>
    //     )
    // }

    const paymentActionsMenu: MenuProps['items'] = [
        payment?.status === 'succeeded' && {
            key: 'change-status-to-pending',
            label: 'Cambiar estado a "Pendiente"',
            onClick: PaymentSucceededToPending,
            icon: <ArrowUUpLeft size={18} />,
        },
        payment?.status === 'succeeded' && { type: 'divider' },
        !payment?.livemode && {
            key: 'copy-payment-id',
            label: (
                <CopyAction
                    toCopy={payment?.fid}
                    loading={thisPaymentHasAnyLoading}
                    isMenu
                    menuTitle="Copiar ID de pago"
                />
            ),
            icon: <ClipboardText size={18} />,
        },
        (payment?.shortURL || payment?.shortUrl) &&
            payment?.status !== 'succeeded' &&
            payment?.status !== 'review_requested' && {
                key: 'copy-short-url',
                label: (
                    <CopyAction
                        toCopy={payment?.shortURL || payment?.shortUrl}
                        loading={thisPaymentHasAnyLoading}
                        isMenu
                        menuTitle="Copiar liga de pago"
                    />
                ),
                icon: <Link size={18} />,
            },
        payment?.status !== 'succeeded' &&
            payment?.status !== 'paid' &&
            canCreatePayment && {
                key: 'send-by-email',
                label: (
                    <EmailActions
                        action={() => {}}
                        loading={loading.find((l) => l.id === payment?.id && l.action === `email`) !== undefined}
                        isMenu
                    />
                ),
                onClick: async () => {
                    const paymentClient = await clientDataHelper(payment.clientId)
                    dispatch(openModal('sendByEmailModalVisible'))
                    dispatch(
                        setElementSelected({
                            element: 'toEmail',
                            data: { ...payment, elementType: 'payments' },
                        }),
                    )
                    dispatch(
                        setData({
                            item: 'client',
                            data: paymentClient,
                        }),
                    )
                },
                icon: <PaperPlaneTilt size={18} />,
            },
        payment?.shortURL &&
            payment?.status !== 'succeeded' &&
            payment?.status !== 'canceled' && {
                key: 'share-for-whatsapp',
                label: 'Compartir por WhatsApp',
                onClick: () =>
                    ShareViaWhatsapp(
                        `Puedes realizar el pago por ${payment?.amount} ${payment?.currency} en el siguiente enlace! 😉 : ${payment?.shortURL}`,
                    ),
                icon: <WhatsappLogo size={18} />,
            },
        { type: 'divider' },
        {
            key: 'issue-an-invoice',
            label: 'Emitir Factura',
            onClick: () => {
                const ctxClient = clients.find(
                    (c: any) =>
                        c.id === payment?.client?.id || c.id === payment?.clientId || c.id === payment?.clientID,
                )

                dispatch(
                    setData({
                        item: 'client',
                        data: ctxClient || payment?.client,
                    }),
                )
                dispatch(
                    setData({
                        item: 'selectedServices',
                        data: payment?.items,
                    }),
                )
                dispatch(
                    setData({
                        item: 'invoice',
                        data: {
                            payments: [payment?.id],
                            paymentForm: payment?.payment_form || payment?.paymentForm,
                            paymentMethod: payment?.payment_method || payment?.paymentMethod,
                            currency: payment?.currency,
                            exchange_rate: payment?.exchange_rate || payment?.exchangeRate || 1,
                        },
                    }),
                )

                dispatch(openModal('invoiceModalVisible'))
            },
            icon: <PlusCircle size={18} />,
        },
        {
            key: 'to-review',
            label: 'Revisar Pago',
            onClick: () => {
                dispatch(
                    setData({
                        item: 'payment',
                        data: payment,
                    }),
                )
                dispatch(openModal('approveModalVisible'))
            },
            icon: <File size={18} />,
        },
        payment?.next_action?.display_bank_transfer_instructions &&
            payment?.status !== 'succeeded' && {
                key: 'display-bank-transfer-info',
                label: 'Ver instrucciones de transferencia',
                onClick: () => {
                    dispatch(
                        setData({
                            item: 'payment',
                            data: payment,
                        }),
                    )
                    dispatch(openModal('stripeBankInstructionsVisible'))
                },
                icon: <Bank size={18} />,
            },
        { type: 'divider' },
        ['requires_payment_method', 'requires_action', 'requires_confirmation'].includes(payment?.status) && {
            key: 'cancel-payment',
            label: <CancelAction />,
            icon: <Prohibit size={18} />,
        },
    ].filter(Boolean) // Filter out any false or null entries

    return (
        <Row>
            <Dropdown menu={{ items: paymentActionsMenu }}>
                <DotsThree size={24} style={{ cursor: 'pointer' }} />
            </Dropdown>
        </Row>
    )
}

export default PaymentsActions
