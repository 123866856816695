import { Col, Form, FormInstance, Row, Switch, Typography, message } from 'antd'
import SelectClientCard from '../Cards/SelectClientCard'
import ItemsSelection from '../ComponentIndividual/ItemsSelection'
import PaymentsMethods from '../ComponentIndividual/PaymentsMetods'
import { CheckBoxInput } from './Inputs'
import { generateCode, getItemsAmounts } from '../../functions/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../context/dataSlice'
import PaymentConfig from '../ComponentIndividual/PaymentLimit'
import ProductsServicesTotals from '../ComponentIndividual/ProductsServicesTotals'
import moment from 'moment'
import { SummarizeMessage } from '../Calendarize/SummarizeRecurring'
import { manageAutomations } from '../../../helpers/helperFunctions'
import { arrayUnion, doc, getFirestore, updateDoc } from 'firebase/firestore'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { useAnalytics, useAuth } from 'reactfire'
import { logEvent } from 'firebase/analytics'
import RecurringEventDetails from '../ComponentIndividual/RecurringEventDetails'
import { usePostHog } from 'posthog-js/react'
import { InvoiceEmailsComponent } from '../invoices/InvoiceComponents'
import { useEffect } from 'react'

const CreatePaymentForm = ({
    form,
    onFinish,
    workWith,
    setloading,
    setStep,
}: {
    form: FormInstance
    onFinish?: (v: any) => void
    workWith: 'payment' | 'recurringPayment'
    setloading: Function
    setStep: Function
}) => {
    const posthog = usePostHog()
    const dispatch = useDispatch()
    const { selectedServices, recurringEventData, testmode, payment, client, custom_method_types } = useSelector(
        (state: any) => state.data,
    )
    const { team } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const { user } = useSelector((state: any) => state.user)
    const auth = useAuth()
    const analytics = useAnalytics()
    const [calendarForm] = Form.useForm()

    useEffect(() => {
        const uniqueEmails = Array.from(new Set([client?.email, ...(client?.bcc || []), ...(team?.contactEmails || [])]))
        form.setFieldsValue({
            emails: uniqueEmails,
        })

        // eslint-disable-next-line
    }, [client])

    const checkRequirements = async () => {
        try {
            await form.validateFields()
        } catch (error) {
            throw new Error('Por favor completa los campos requeridos')
        }
        if (selectedServices.length === 0) {
            message.error('Por favor selecciona al menos un servicio')
            throw new Error('Por favor selecciona al menos un servicio')
        }
        if (!client && payment?.from !== 'paymentLink') {
            message.error('Por favor selecciona un cliente')
            throw new Error('Por favor selecciona un cliente')
        }
    }

    const preparePayment = (values: any) => {
        const id = payment?.fid ?? payment?.id ?? generateCode(15, 'payment')

        const p = {
            ...values,
            team: team?.id,
            billingAccount: billingAccount?.id,
            livemode: !values?.test,
            automations: [],
            amount_capturable: 0,
            customer: client ?? null,
            created: moment().valueOf(),
            timestamp: moment().valueOf(),
            amount_received: 0,
            capture_method: 'automatic',
            amount: getItemsAmounts(selectedServices).total * 100,
            status: 'pending',
            viewed: 0,
            sms: client?.phone && user?.twilio,
            receipt_email: client?.email ?? null,
            owner: user.uid,
            payment_method: values.payment_method,
            currency: values.currency ?? 'MXN',
            exchange: values.exchange ?? 1,
            exchangeRate: values.exchange ?? 1,
            items: selectedServices,
            clientID: client?.id ?? null,
            clientId: client?.id ?? null,
            metadata: { items: 1, owner: user.uid, internalID: id },
            internalStatus: 'pending',
            object: 'payment',
            id,
            custom_method_types: custom_method_types,
            client: client ?? null,
            fid: id,
            isManual: false,
            limitDaysToPay: values.limitDaysToPay ?? null,
            from: payment?.from ?? 'manual',
            invoices: payment?.invoices ?? [],
            receipts: payment?.receipts ?? [],
            payments: payment?.payments ?? [],
        }

        if (values.automations) {
            p.automations = manageAutomations({ toCreate: 'invoice', from: 'payments' })
        }

        return p
    }

    const prepareRecurringEvent = (p: any) => {
        const id = recurringEventData?.id ?? generateCode(15, 're')
        const sm = SummarizeMessage({
            startDate: recurringEventData?.startDate,
            endDate: recurringEventData?.endDate,
            onTime: recurringEventData?.onTime,
            temporality: recurringEventData?.temporality,
            onWeekday: recurringEventData?.onWeekday,
            onDay: recurringEventData?.onDay,
            monthlyInterval: recurringEventData?.monthlyInterval,
        })
        if (!sm.nextHit) {
            message.error(sm.message)
            return null
        }
        const recurringEvent = {
            team: team?.id,
            billingAccount: team?.billingAccount,
            owner: user.uid,
            processed: false,
            replicateElement: { ...p, from: 'recurringEvent' },
            elementType: 'payment',
            id,
            startDate: moment(recurringEventData?.startDate).valueOf(),
            startDateUTC: moment(recurringEventData.startDate).utc().valueOf(),
            startDateString: moment(recurringEventData.startDate).format('D MMMM YYYY HH:mm'),
            endDate: moment(recurringEventData.endDate).valueOf(),
            endDateUTC: moment(recurringEventData.endDate).utc().valueOf(),
            endDateString: moment(recurringEventData.endDate).format('D MMMM YYYY HH:mm'),
            onWeekday: recurringEventData.onWeekday ?? null,
            onDay: recurringEventData.onDay ?? null,
            onTime: recurringEventData.onTime,
            temporality: recurringEventData.temporality ?? null,
            monthlyInterval: recurringEventData.monthlyInterval ?? null,
            timestamp: recurringEventData.timestamp ?? moment().valueOf(),
            timestampUTC: recurringEventData?.timestampUTC ?? moment().utc().valueOf(),
            client: client ?? null,
            clientID: client?.id ?? null,
            clientId: client?.id ?? null,
            nextRun: sm.nextHit,
            nextRunUTC: sm.nextHit ? moment(sm.nextHit).utc().valueOf() : null,
            nextRunString: sm.nextHit ? moment(sm.nextHit).format('D MMMM YYYY HH:mm') : null,
            status: 'active',
            type: 'payment',
            from: payment?.oldFrom ?? 'manual',
            startEndDate: [
                moment(recurringEventData?.startDate).format(),
                moment(recurringEventData?.endDate).format(),
            ],
            lastUpdate: moment().valueOf(),
        }
        return recurringEvent
    }

    const RequestPayment = async (v: any) => {
        try {
            setloading(true)
            const p = preparePayment(v)

            if (p.custom_method_types.length === 0) {
                message.error('Por favor selecciona al menos un método de pago')
                return setloading(false)
            }

            await checkRequirements()
            if (payment?.from === 'recurringEvent') {
                const recurringEvent = prepareRecurringEvent(p)
                if (!recurringEvent) return setloading(false)

                if (recurringEventData?.id) {
                    await updateDoc(doc(getFirestore(), 'recurringEvents', recurringEventData?.id), {
                        ...recurringEvent,
                        updateList: arrayUnion(moment().valueOf()),
                    })
                } else {
                    await SignedInternalAPIRequest({ ...recurringEvent }, 'createRecurringEventSeats', auth.currentUser)
                }
                dispatch(
                    setData({
                        item: 'recurringEventData',
                        data: recurringEvent,
                    }),
                )
                dispatch(
                    setData({
                        item: 'payment',
                        data: p,
                    }),
                )
                setloading(false)
                setStep(1)
            } else if (payment?.from === 'paymentLink') {
                const paymentLinkObj = {
                    id: payment?.id,
                    team: team.id,
                    currency: p.currency,
                    exchange: p.exchange,
                    items: selectedServices,
                    automations: p.automations ?? [],
                    livemode: !p.test,
                    status: 'active',
                    owner: user.uid,
                    metadata: {},
                    billingAccount: billingAccount.id,
                    description: p.description || '',
                    custom_method_types: p.custom_method_types,
                }

                const resp = await SignedInternalAPIRequest(
                    {
                        paymentLink: paymentLinkObj,
                        type: 'payment_link',
                        action: payment?.id ? 'update' : 'create',
                    },
                    'paymentLinksApp/v1/create',
                    auth.currentUser,
                )

                if (resp.error) {
                    message.error(resp?.message ?? 'Ocurrió un error en el servidor')
                    setloading(false)
                    return
                }

                dispatch(
                    setData({
                        item: 'payment',
                        data: p,
                    }),
                )

                setStep(1)
                message.success('Enlace de pago creado correctamente')
            } else {
                
                const paymentResponse = await SignedInternalAPIRequest(
                    {
                        payment: p,
                        billingAccount: billingAccount.id,
                        team: team.id,
                        type: 'payment_request_2',
                    },
                    'paymentsHandler',
                    auth.currentUser,
                )

                if (paymentResponse.error) {
                    message.error(paymentResponse?.message ?? 'Ocurrió un error en el servidor')
                    setloading(false)
                    message.error(paymentResponse?.message ?? 'Ocurrió un error en el servidor')
                }
                try {
                    posthog.capture('payment_created', {
                        id: p.id,
                    })
                } catch (error) {}
                dispatch(
                    setData({
                        item: 'payment',
                        data: p,
                    }),
                )
                setStep(1)
                message.success('Pago creado correctamente')
                setloading(false)
                logEvent(analytics, 'paymentCreated', {})
            }
        } catch (error: any) {
            setloading(false)
            message.error(error.message)
        }
    }

    const InputsToRender = [
        {
            key: 'client',
            title: 'Cliente',
            content: <SelectClientCard />,
        },
        {
            key: 'services',
            title: 'Productos / Servicios',
            style: { marginTop: payment?.from === 'paymentLink' ? '0px' : '80px' },
            content: (
                <>
                    <ItemsSelection hideTitle={true} />
                    <ProductsServicesTotals boxed={false} />
                </>
            ),
        },
        {
            key: 'paymentMethods',
            title: 'Métodos disponibles',
            content: <PaymentsMethods />,
        },
        {
            key: 'paymentDetails',
            title: 'Detalles del pago',
            content: <PaymentConfig form={form} />,
        },
        {
            key: 'emails',
            title: 'Entrega',
            content: <InvoiceEmailsComponent />,
        },
        {
            key: 'automations',
            title: 'Automatizaciones',
            content: (
                <CheckBoxInput
                    disabled={!getItemsAmounts(selectedServices, true).canSend}
                    name="automations"
                    content={
                        <>
                            {' '}
                            <Typography.Text type="secondary" className="strong" style={{ fontSize: '13px' }}>
                                Crear una factura cuando el pago esté completo
                            </Typography.Text>
                        </>
                    }
                />
            ),
        },
        {
            key: 'testmode',
            title: 'Pago en modo pruebas',
            content: (
                <Form.Item label="¿Deseas solicitar el pago en modo pruebas?" valuePropName="checked" name="test">
                    <Switch />
                </Form.Item>
            ),
        },
    ]

    const HandleInputsToRender = () => {
        if (payment?.from === 'paymentLink') {
            return InputsToRender.filter((i) => i.key !== 'client')
        }

        return InputsToRender
    }

    return (
        <>
            <Form
                form={form}
                onFinish={RequestPayment}
                initialValues={{
                    test: recurringEventData?.id ? !recurringEventData?.replicateElement?.livemode : testmode,
                }}
                layout="vertical"
            >
                {/*<Typography.Title className="bigparagraph" level={5}>
                    1. Cliente
                </Typography.Title>
                <SelectClientCard />
                <Col>
                    <Typography.Title
                        className="bigparagraph"
                        level={5}
                        style={{ marginTop: '80px' }}
                    >
                        2. Productos / Servicios
                    </Typography.Title>
                    <ItemsSelection hideTitle={true} />
                    <ProductsServicesTotals boxed={false} />
                </Col>
                <Typography.Title className="bigparagraph" level={5}>
                    3. Detalles del pago
                </Typography.Title>
                <PaymentConfig form={form} />
                <Col style={{ marginTop: '80px' }}>
                    <Typography.Title className="bigparagraph" level={5}>
                        4. Métodos disponibles
                    </Typography.Title>
                    <PaymentsMethods />
                </Col>
                <Col style={{ marginTop: '20px' }}>
                    <Typography.Title className="bigparagraph" level={5}>
                        5. Automatizaciones
                    </Typography.Title>
                    <CheckBoxInput
                        disabled={!getItemsAmounts(selectedServices, true).canSend}
                        name="automations"
                        content={
                            <>
                                {' '}
                                <Typography.Text
                                    type="secondary"
                                    className="strong"
                                    style={{ fontSize: '13px' }}
                                >
                                    Crear una factura cuando el pago esté completo
                                </Typography.Text>
                            </>
                        }
                    />
                </Col>

                <Row justify="start">
                    <Col xs={24} md={12}>
                        <Typography.Title className="bigparagraph" level={5}>
                            6. Pago en modo pruebas
                        </Typography.Title>
                        <Form.Item
                            label="¿Deseas solicitar el pago en modo pruebas?"
                            valuePropName="checked"
                            name="test"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    </Row> */}
                {HandleInputsToRender().map((i, index) => (
                    <Row key={i.key} justify={'start'} style={{ ...i.style }}>
                        <Col xs={24} md={24}>
                            <Typography.Title className="bigparagraph" level={5}>
                                {index + 1}. {i.title}
                            </Typography.Title>
                            {i.content}
                        </Col>
                    </Row>
                ))}
            </Form>
            {payment?.from === 'recurringEvent' && (
                <>
                    <RecurringEventDetails form={calendarForm} />
                </>
            )}
        </>
    )
}

export default CreatePaymentForm
