import { TeamDef } from '../../../interfaces/teamDef'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { SearchParamInURL } from '../../functions/helpers'

export const getStripeConnectLink = async ({ team, auth }: { team: TeamDef; auth: any }) => {
    const stripeClientId = process.env.REACT_APP_STRIPE_CLIENT_ID
    const stripeRedirect = getStripeRedirects(true)

    try {
        let link = null
        if (!team?.stripe?.completed) {
            link = {
                url: `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${stripeClientId}&redirect_uri=${stripeRedirect}&scope=read_write`,
            }
        } else if (team?.stripe) {
            link = await SignedInternalAPIRequest(
                {
                    teamId: team.id,
                    billingAccountId: team.billingAccount,
                    type: 'onboarding_link',
                    redirectUrl: getStripeRedirects(true),
                    refreshUrl: getStripeRedirects(true),
                    cancelUrl: getStripeRedirects(),
                    isStaging: isStagingOrLocal(),
                },
                'stripeActions',
                auth.currentUser,
            )
        }
        return link
    } catch (error) {
        throw error
    }
}

export const finishStripeConnection = async ({
    setLoading = (n: boolean) => {},
    authUser,
}: {
    setLoading: (n: boolean) => void
    authUser: any
}) => {
    const platform = SearchParamInURL('platform')
    if (platform === 'stripe') {
        const code = SearchParamInURL('code')
        const CreateConnectionStripe = async () => {
            try {
                const link = await SignedInternalAPIRequest(
                    {
                        type: 'connect_oauth',
                        code,
                        returnUrl: getStripeRedirects(),
                        refreshUrl: getStripeRedirects(),
                        isStaging: isStagingOrLocal(),
                    },
                    'stripeActions',
                    authUser,
                )
                setLoading(false)

                return window.open(link.url, '_self')
            } catch (error) {
                setLoading(false)
            }
        }

        if (code) {
            setLoading(true)
            CreateConnectionStripe()
        }
    }
}

export const getStripeRedirects = (isOauth = false) => {
    const url = window.location.href
    const path = isOauth ? 'integrations?platform=stripe' : ''

    if (url.includes('localhost')) return `http://localhost:3000/${path}`
    if (url.includes('staging')) return `https://staging.gigstack.pro/${path}`
    return `https://app.gigstack.pro/${path}`
}

export const isStagingOrLocal = () => {
    const url = window.location.href

    if (url.includes('localhost')) return true
    if (url.includes('staging')) return true
    return false
}
