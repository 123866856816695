import { Button, Divider, Form, message, Modal, Row, Space, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../context/modalsSlice'
import { IntegrationOnboarding } from '../../components/Integrations/IntegrationOnboarding'
import { integrations } from '../../datasets/Integrations'
import { useEffect, useState } from 'react'
import { addParamToUrl } from '../../functions/UrlParams'
import { Robot } from '@phosphor-icons/react'
import { GetTeamHelper } from '../../context/databaseContextHelpers'
import SwitchHolder from '../../components/Forms/SwitchHolder'
import { TextInput } from '../../components/Forms/Inputs'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { useAuth } from 'reactfire'
import { updateAutomaticInvoicingIntegration } from './helpers'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'

export const PayPalModal = () => {
    const dispatch = useDispatch()
    const auth = useAuth()
    const { paypalModalVisible } = useSelector((state: any) => state.modals)
    const { team } = useSelector((state: any) => state.team)
    // const { testmode } = useSelector((state: any) => state.data)
    const [loading, setLoading] = useState(false)
    const integration = integrations(team).find((i) => i.id === 'paypal')
    const [step, setStep] = useState(team?.paypal?.completed ? 1 : 0)
    const [automaticInvoicing, setAutomaticInvoicing] = useState(team?.paypal?.automaticInvoicing ?? false)
    const [form] = Form.useForm()

    useEffect(() => {
        if (paypalModalVisible) {
            addParamToUrl('open', 'paypal')
        }
    }, [paypalModalVisible])

    const handleSubmit = async () => {
        try {
            setLoading(true)

            const values = await form.validateFields()

            if(team?.paypal?.completed) {
                await updateDoc(doc(getFirestore(), `teams/${team?.id}`), {
                    'paypal.defaults.serviceDescription': values.serviceDescription
                })
                await GetTeamHelper(team?.id, dispatch)
                setLoading(false)
                
                dispatch(closeModal('paypalModalVisible'))
                return message.success('Configuración actualizada')
            }

            const data = await SignedInternalAPIRequest(
                { ...values, teamId: team?.id },
                'paypalconnectionapp/v1/connect',
                auth.currentUser
            )
            console.log("DATA", data)
            if (data?.completed) {
                await GetTeamHelper(team?.id, dispatch)
                setAutomaticInvoicing(true)
                message.success('Configuración actualizada')
            }
            setLoading(false)
        } catch (error: any) {
            console.error('Error: ', error)

            message.error(error?.message || 'Ocurrió un error al actualizar la configuración')
            setLoading(false)
        }
    }


    const ToRender = () => {
        switch (step) {
            case 0:
                return (
                    <IntegrationOnboarding
                        logo={integration?.logo ?? ''}
                        title="Conecta PayPal con gigstack"
                        description="Cobra y factura automáticamente con PayPal"
                        bullets={[
                            { type: 'positive', description: 'Facturar automáticamente tus ventas' },
                            { type: 'positive', description: 'Sincroniza automáticamente tus ventas' },
                        ]}
                        IntegrationFooter={
                            <Row justify="space-between" style={{ padding: '20px 24px 20px 24px' }}>
                                <Row>
                                    <Button type="primary" onClick={() => setStep(step + 1)}>
                                        Conectar
                                    </Button>
                                </Row>
                            </Row>
                        }
                    />
                )
            case 1:
                return (
                    <div style={{ padding: '20px 24px' }}>
                        <Typography.Text className="mediumparagraphbold">Conecta tu cuenta en PayPal</Typography.Text>
                        <Typography.Text className="smallparagraph descriptions">
                            Conecta tu cuenta y automatiza todas las transacciones que recibas.
                        </Typography.Text>

                        <Form form={form} layout="vertical" style={{ marginTop: '15px' }} onFinish={handleSubmit}>
                            <Divider />
                            {team?.paypal?.completed && (
                                <Space direction='vertical'>
                                    <SwitchHolder
                                        changeState={async () => {
                                            await updateAutomaticInvoicingIntegration({
                                                automaticInvoicing,
                                                dispatch, 
                                                setAutomaticInvoicing,
                                                team,
                                                integrationName: 'paypal'
                                            })
                                        }}
                                        switchState={automaticInvoicing}
                                        icon={<Robot className="icon clickable" weight="regular" size={16} />}
                                        title={'Activar facturación automática de paypal'}
                                        description="Intentaremos facturar automáticamente tus ventas de paypal, si no es posible, crearemos un recibo de venta para que tu cliente genere su factura"
                                    />

                                    <Divider />
                                    
                                    <TextInput defaultValue={team?.paypal?.defaults?.serviceDescription || 'Venta de paypal'} label="Descripción por defecto para las ordenes" name="serviceDescription" description='Usaremos esta descripción para las órdenes sin descripción' />    
                                    
                                    <Row justify={'end'}>
                                        <Button loading={loading} type="primary" htmlType="submit">
                                            Guardar
                                        </Button>
                                    </Row>
                                </Space>
                            )}
                            {!team?.paypal?.completed && (
                                <>
                                    <TextInput
                                        label="Client ID Live"
                                        name="clientId"
                                        rules={[{ required: true, message: 'Por favor ingrese Client ID Live' }]}
                                    />
                                    <TextInput
                                        label="Client ID Test"
                                        name="clientIdTest"
                                        rules={[{ required: true, message: 'Por favor ingrese su API Key Test' }]}
                                    />

                                    <Divider />

                                    <TextInput
                                        label="Secret Live"
                                        name="clientSecret"
                                        rules={[{ required: true, message: 'Por favor ingrese su API Key Test' }]}
                                    />

                                    <TextInput
                                        label="Secret Test"
                                        name="clientSecretTest"
                                        rules={[{ required: true, message: 'Por favor ingrese su API Key Test' }]}
                                    />
                                    <Row justify="end" style={{ marginTop: '35px' }}>
                                        <Button loading={loading} type="primary" htmlType="submit">
                                            Conectar mi cuenta
                                        </Button>
                                    </Row>
                                </>
                            )}
                        </Form>
                    </div>
                )
            default:
                return <></>
        }
    }

    return (
        <Modal
            open={paypalModalVisible}
            onCancel={() => {
                dispatch(closeModal('paypalModalVisible'))
            }}
            className="integrationModal"
            title={null}
            footer={null}
            style={{ top: 20 }}
        >
            <ToRender />
        </Modal>
    )
}
