import { Button, Checkbox, Col, Divider, Form, FormInstance, Row, Typography, message } from 'antd'
import { useEffect, useState } from 'react'
import { CaretDoubleRight } from '@phosphor-icons/react'
import { ChipsSelect, MetadataListInput, SelectInput, TextInput } from '../Forms/Inputs'
import { useDispatch, useSelector } from 'react-redux'
import { countryLabeledListWithCodeAlpha3 } from '../../datasets/Countries'
import { HandleMetadataInput, generateCode, invoiceUsage } from '../../functions/helpers'
import ErrorsInFormComponent from '../Forms/ErrorsInFormComponent'
import { taxRegimes } from '../../datasets/Fiscal'
import { ClientDef } from '../../../interfaces/clientDef'
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import { pushNewData, setData } from '../../context/dataSlice'
import { closeModal } from '../../context/modalsSlice'
import { removeParamFromUrl } from '../../functions/UrlParams'

const CreateClientForm = ({
    closeElement,
    hideTitle,
    passForm,
    useClient,
}: {
    closeElement: string
    hideTitle?: boolean
    passForm?: (func: FormInstance) => void
    useClient?: boolean
}) => {
    const dispatch = useDispatch()
    const fs = getFirestore()

    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)
    const { client, testmode, clients } = useSelector((state: any) => state.data)
    const [country, setCountry] = useState('')
    const [formErrors, setFormErrors] = useState<any[]>([])
    const [loading, setLoading] = useState<any[]>([])

    const [showMoreEmails, setShowMoreEmails] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {
        if (client) {
            let processedMeta = Object.keys(client?.metadata ?? {})
                .map((key) => {
                    return {
                        key,
                        value: client?.metadata[key],
                    }
                })
                .filter((meta) => meta.value) // remove empty values
            if (client?.bcc) {
                setShowMoreEmails(true)
            }
            if (client?.country) {
                const countryName =
                    typeof client?.country === 'string' ? client?.country : (client?.country?.label ?? '')
                setCountry(countryName)
            }
            form.setFieldsValue({
                ...client,
                metadata: processedMeta,
                address: client?.address?.street,
                country:
                    typeof client?.address?.country === 'string'
                        ? client?.address?.country
                        : (client?.address?.country?.label ?? 'MEX'),
                zip: client?.address?.zip,
            })
        }
    }, [country, client, form, clients])

    const mexicanRules = [
        {
            pattern: /[A-ZÑ&]{3,4}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])(?:[A-Z\d]{3})/,
            message: 'Por favor ingrese un RFC válido',
        },
    ]

    const FinishForm = async (v: any) => {
        if (v?.keyCode === 13) {
            return
        }

        setFormErrors([])
        let id = client?.id ?? generateCode(10, 'client')

        try {
            setLoading([
                ...loading,
                {
                    id,
                    action: 'create',
                },
            ])
            await form.validateFields()

            const metadata = HandleMetadataInput({ metadataValue: (form.getFieldsValue()?.metadata || []) ?? [] })

            const newClient = {
                ...(client ?? {}),
                ...v,
                email: v.email ?? null,
                zip: v.zip ?? null,
                company: v.company ?? null,
                country: v?.country ?? 'MEX',
                tax_system: v?.tax_system ?? null,
                use: v?.use && v?.use !== '' ? v?.use : null,
                legal_name: v?.legal_name ?? null,
                rfc: v?.rfc ?? null,
                address: {
                    zip: v?.zip ?? null,
                    street: v?.address ?? null,
                    country: v?.country ?? 'MEX',
                },
                metadata: metadata?.metadata ?? {},
                id,
                from: 'manual',
                team: team?.id,
                livemode: !testmode,
                billingAccount: team?.billingAccount,
                timestamp: client?.timestamp ?? moment().valueOf(),
            } as ClientDef

            if (client) {
                newClient.lastEdited = {
                    by: user.uid,
                    timestamp: moment().valueOf(),
                }
            }

            // delete undefined values
            Object.keys(newClient).forEach(
                (key) => (newClient as any)[key] === undefined && delete (newClient as any)[key],
            )

            if (!client) {
                await setDoc(doc(fs, 'clients', id), newClient, { merge: true })
            } else {
                await updateDoc(doc(fs, 'clients', client.id), newClient as any, { merge: true })
            }

            if (!client) {
                dispatch(
                    pushNewData({
                        item: 'clients',
                        data: newClient,
                    }),
                )
                form.resetFields()
            }

            if (useClient) {
                dispatch(
                    setData({
                        item: 'client',
                        data: newClient,
                    }),
                )
                dispatch(
                    setData({
                        item: 'clients',
                        data: clients.map((c: any) => {
                            if (c.id === client?.id) {
                                return newClient
                            }
                            return c
                        }),
                    }),
                )
            } else if (client) {
                dispatch(
                    setData({
                        item: 'clients',
                        data: clients.map((c: any) => {
                            if (c.id === client?.id) {
                                return newClient
                            }
                            return c
                        }),
                    }),
                )
                dispatch(
                    setData({
                        item: 'client',
                        data: newClient,
                    }),
                )
            }
            removeParamFromUrl('open')
            form.resetFields()
            setLoading(loading.filter((l: any) => l.action === 'create'))
            dispatch(closeModal(closeElement))
        } catch (error: any) {
            if (error?.errorFields?.length > 0) {
                setFormErrors(error.errorFields)
                message.error(error?.message ?? 'Ocurrió un error desconocido')
            }
            setLoading(loading.filter((l: any) => l.action === 'create'))
        }
    }

    useEffect(() => {
        if (passForm) {
            passForm(form)
        }
    }, [passForm, form])

    return (
        <div className="d-flex flex-column">
            {!hideTitle && <Typography.Text className="mediumheader">Crear nuevo cliente</Typography.Text>}
            {!hideTitle && <Divider />}
            <Form
                disabled={loading.some((l: any) => l.action === 'create')}
                form={form}
                layout="vertical"
                onFinish={(v) => {
                    FinishForm(v)
                }}
            >
                <Typography.Text className="bigparagraphbold" style={{ marginBottom: '10px' }}>
                    1. Información del cliente
                </Typography.Text>
                {/*PERSONAL INFORMATION */}
                <Row gutter={{ xs: 10, md: 12 }}>
                    <Col xs={24} lg={24}>
                        <TextInput
                            name="name"
                            placeholder="ej. Juan Diego Valdez"
                            label="Nombre del cliente"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese el nombre del cliente',
                                },
                            ]}
                            description="Nos dirigiremos a este cliente por este nombre."
                        />
                    </Col>
                    <Col xs={24} lg={24}>
                        <TextInput
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Por favor ingresa un correo válido',
                                },
                            ]}
                            placeholder="ej. juandiego@correo.com"
                            label="Correo electrónico"
                        />
                    </Col>
                    <Col xs={24} style={{ margin: '5px 0px' }}>
                        <Checkbox checked={showMoreEmails} onChange={(v: any) => setShowMoreEmails(v.target.checked)}>
                            Añadir más correos?
                        </Checkbox>
                    </Col>
                    {showMoreEmails && (
                        <Col xs={24} style={{ marginTop: '15px' }}>
                            <ChipsSelect
                                label="Correos adicionales"
                                name={'bcc'}
                                change={() => {}}
                                options={[]}
                                placeholder="Correos extra"
                                description="Agregaremos los correos adicionales a tu lista de distribución."
                                empty={
                                    <Row justify="center" style={{ padding: '15px 0px' }}>
                                        <Typography.Text className="smallparagraph descriptions">
                                            Añade correos secundarios
                                        </Typography.Text>
                                    </Row>
                                }
                            />
                        </Col>
                    )}
                    <Col xs={24} lg={24}>
                        <TextInput
                            name="phone"
                            rules={[
                                {
                                    type: 'phone',
                                    message: 'Por favor ingresa un teléfono válido',
                                },
                            ]}
                            placeholder="+520000"
                            label="Teléfono de contacto"
                        />
                    </Col>
                </Row>

                <Typography.Text className="bigparagraphbold" style={{ marginBottom: '10px', marginTop: '25px' }}>
                    2. Información de facturación
                </Typography.Text>

                {/*FISCAL INFORMATION */}
                <Row gutter={{ xs: 10, md: 12 }}>
                    <Col xs={24} lg={24}>
                        <SelectInput
                            name="country"
                            onChange={(v: any) => {
                                form.validateFields(['rfc'])
                                if (v !== 'MEX') {
                                    form.setFieldsValue({
                                        tax_system: '616',
                                    })
                                }
                            }}
                            placeholder="Mexico"
                            label="País"
                            options={countryLabeledListWithCodeAlpha3.map((c: any) => {
                                return {
                                    label: c.name,
                                    value: c.code,
                                }
                            })}
                        />
                    </Col>

                    <Col xs={24} lg={24}>
                        <SelectInput
                            name="tax_system"
                            placeholder="Régimen Fiscal"
                            label="Régimen Fiscal"
                            options={taxRegimes.map((c) => {
                                return {
                                    label: c.label,
                                    value: c.value,
                                }
                            })}
                        />
                    </Col>
                    <Col xs={24} lg={24}>
                        <SelectInput
                            name="use"
                            placeholder="Uso de facturas"
                            label="Uso de los CFDI's"
                            options={invoiceUsage.map((c) => {
                                return {
                                    label: c.label,
                                    value: c.value,
                                }
                            })}
                        />
                    </Col>
                    <Col xs={24} lg={12}>
                        <TextInput name="company" placeholder="Café Valdez" label="Empresa" />
                    </Col>
                    <Col xs={24} lg={12}>
                        <TextInput
                            name="legal_name"
                            placeholder="Nombre Legal de la empresa"
                            label="Razón Social / Nombre legal"
                            description="Sin acentos y sin régimen societario."
                        />
                    </Col>
                    <Col xs={24}>
                        <TextInput
                            name="rfc"
                            onInput={(e: any) => {
                                if (country !== 'MEX') return
                                e.target.value = e.target.value.toUpperCase()
                            }}
                            rules={country === 'MEX' ? mexicanRules : []}
                            placeholder="ej. XAXX010101000"
                            label={
                                country === 'MEX' || !country
                                    ? 'Registro Federal de Contribuyente (RFC)'
                                    : 'Identificador fiscal'
                            }
                            onChange={(v: any) => {
                                //VALIDATE REGEX RFCPFRegex
                            }}
                        />
                    </Col>

                    <Col xs={24} lg={12}>
                        <TextInput name="address" placeholder={`ej. Camino a `} label="Dirección" />
                    </Col>
                    <Col xs={24} lg={12}>
                        <TextInput
                            name="zip"
                            placeholder={`ej. ${team?.address?.zip ?? '10200'}`}
                            label="Código Postal"
                        />
                    </Col>
                </Row>

                <Typography.Text className="bigparagraphbold" style={{ marginBottom: '5px', marginTop: '5px' }}>
                    3. Metadata
                </Typography.Text>

                <Row gutter={{ xs: 10, md: 12 }}>
                    <MetadataListInput name={'metadata'} />
                </Row>
                <ErrorsInFormComponent formErrors={formErrors} form={form} title="Error en los datos" />
                <Row justify="end">
                    <Button type="primary" id="submitbutton" htmlType="submit">
                        {client ? 'Guardar cambios' : 'Crear cliente'}
                        <CaretDoubleRight size={15} style={{ marginLeft: '5px' }} className="" weight="regular" />
                    </Button>
                </Row>
            </Form>
        </div>
    )
}

export default CreateClientForm
