import { collection, doc, getDoc, getDocs, getFirestore, query, updateDoc, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth } from 'reactfire'
import Loader from '../appStates/LoadingView'
import { setTeam, setTeamsBA } from '../context/teamsSlice'
import { setBillingAccount } from '../context/billingAccountSlice'
import { setUser } from '../context/userSlice'
import { Route, Routes, useNavigate } from 'react-router-dom'
import FinishSetup from '../pages/FinishSetup'
import { generateCode, SearchParamInURL } from '../functions/helpers'
import { usePostHog } from 'posthog-js/react'
import moment from 'moment'
import { message, notification } from 'antd'

function AuthScreensWrapper({ children }: { children: JSX.Element }) {
    const posthog = usePostHog()
    const auth = useAuth()
    const fs = getFirestore()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [finishSetup, setfinishSetup] = useState(false)

    const [user, setuser]: any = useState(null)

    const [retried, setretried] = useState(0)
    const navigate = useNavigate()
    const redirect = SearchParamInURL('redirect')

    let correctRed = redirect

    if (SearchParamInURL('code')) {
        correctRed = correctRed + `?code=${SearchParamInURL('code')}`
    }
    if (SearchParamInURL('customPriceId')) {
        correctRed = correctRed + `?customPriceId=${SearchParamInURL('customPriceId')}`
    }

    const GetInitialUserData = async () => {
        try {
            //message.info(auth.currentUser?.uid ?? 'No hay usuario')

            const u = await getDoc(doc(fs, 'users', auth.currentUser?.uid ?? ''))

            setuser(u.data() ?? null)
            dispatch(setUser(u.data()))

            if (u.exists()) {
                try {
                    posthog?.identify(u.data()?.uid, {
                        email: u.data()?.email,
                        name: u.data()?.name,
                    })
                } catch (error) {
                    console.log('🚀 ~ GetInitialUserData ~ error:', error)
                }
                if (!u.data().loggedIn && u.data().defaults?.preventMultipleLoggins) {
                    const sessionId = generateCode(10, 'unique_session')
                    await updateDoc(doc(getFirestore(), `users/${auth.currentUser?.uid}`), {
                        loggedIn: true,
                        uniqueSessionId: sessionId,
                    })
                    // save to local storage
                    localStorage.setItem('gigstackSessionId', sessionId)
                } else if (u.data().loggedIn && u.data().defaults?.preventMultipleLoggins) {
                    const sessionId = localStorage.getItem('gigstackSessionId')
                    if (sessionId !== u.data().uniqueSessionId) {
                        notification.warning({
                            message: 'Sesión duplicada',
                            description: 'Esta cuenta ya tiene una sesión abierta en otro dispositivo',
                            duration: 5000,
                        })
                    }
                }
                if (u.data().lastBillingAccountViewed) {
                    GetInititalBAData({ user: u.data() })
                } else {
                    navigate(`/finishSetup${redirect ? `?redirect=${correctRed}` : ''}`)
                    return setfinishSetup(true)
                }
            } else {
                // MAKE A RETRY HERE OF 5 TIMES IN 3 SECONDS OF DIFFERENCE EACH
                setTimeout(() => {
                    setretried((prev) => prev + 1)
                    if (retried > 4) {
                        return
                    }
                    GetInitialUserData()
                }, 3000)
            }
        } catch (error: any) {
            console.log('🚀 ~ GetInitialUserData ~ error:', error)
            message.error(error?.message ?? 'Error al cargar la información del usuario')
        }
    }

    const GetInititalBAData = async ({ user }: { user: any }) => {
        try {
            const ba = await getDoc(doc(fs, 'billingAccounts', user.lastBillingAccountViewed ?? ''))
            dispatch(setBillingAccount(ba.data()))
            if (user.lastTeamViewed) {
                try {
                    posthog?.identify(user.uid, {
                        email: user.email,
                        name: user.name,
                        lastTeamViewed: user.lastTeamViewed,
                    })
                } catch (error) {
                    console.log('🚀 ~ GetInititalBAData ~ error:', error)
                }
                GetInitialTeamData({ user: user, billingAccount: ba.data() })
            } else {
                navigate(`/finishSetup${redirect ? `?redirect=${correctRed}` : ''}`)
                return setfinishSetup(true)
            }
        } catch (error) {
            console.log('🚀 ~ GetInititalBAData ~ error:', error)
        }
    }

    const GetInitialTeamData = async ({ user, billingAccount }: { user: any; billingAccount: any }) => {
        try {
            const t = await getDoc(doc(fs, 'teams', user.lastTeamViewed ?? ''))
            if (!t.exists()) {
                navigate(`/finishSetup${redirect ? `?redirect=${correctRed}` : ''}`)
                setfinishSetup(true)
                return
            }

            const teams = await getDocs(
                query(collection(fs, 'teams'), where('membersIds', 'array-contains', user?.uid ?? 'js')),
            )
            dispatch(setTeam(t.data()))
            dispatch(setTeamsBA(teams.docs.map((doc) => doc.data())))
            if (!t?.data().brand?.alias) {
                navigate(`/finishSetup${redirect ? `?redirect=${correctRed}` : ''}`)
                setfinishSetup(true)
                return
            }
            const team = t.data()
            setLoading(false)
            if (redirect) {
                let correctRed = redirect
                if (SearchParamInURL('code')) {
                    correctRed = correctRed + `?code=${SearchParamInURL('code')}`
                }
                if (SearchParamInURL('customPriceId')) {
                    correctRed = correctRed + `?customPriceId=${SearchParamInURL('customPriceId')}`
                }
                navigate(correctRed)
            }
            const userData = user
            window.Intercom('boot', {
                app_id: 'acgd4n2q',
                email: userData?.email,
                created_at: moment(userData?.timestamp).unix(),
                name: userData?.name,
                user_id: userData?.uid,
                user_hash: userData?.intercomHash,
                avatar: {
                    type: 'avatar',
                    image_url: team?.brand?.logo ?? userData?.profilePic,
                },
                billing_account_id: team?.billingAccount,
                billing_account_name: billingAccount?.legalName ?? billingAccount?.name,
                team_id: team?.id,
                team_name: team?.brand?.alias,
                facturapi_completed: team?.facturapi?.completed ?? false,
                stripe_completed: team?.stripe?.completed ?? false,
                openpay_completed: team?.openpay?.completed ?? false,
                whmcs_completed: team?.whmcs?.completed ?? false,
                stripe_auto_invoice_active: team?.stripe?.automaticInvoicing ?? false,
                Credits: billingAccount?.credits ?? 0,
                payasyougo: billingAccount?.payAsYouGo ?? false,
                Membership: '',
                custom_launcher_selector: '#intercomButtonOpen',
            })
        } catch (error) {
            console.log('🚀 ~ GetInitialTeamData ~ error:', error)
        }
    }

    useEffect(() => {
        if (auth.currentUser?.uid && !user) {
            GetInitialUserData()
        }
        //eslint-disable-next-line
    }, [auth.currentUser])

    if (finishSetup) {
        return (
            <Routes>
                <Route
                    path="/finishSetup"
                    element={
                        <FinishSetup
                            endsetup={() => {
                                GetInitialUserData()
                                if (redirect) {
                                    let correctRed = redirect
                                    if (SearchParamInURL('code')) {
                                        correctRed = correctRed + `?code=${SearchParamInURL('code')}`
                                    }
                                    if (SearchParamInURL('customPriceId')) {
                                        correctRed = correctRed + `?customPriceId=${SearchParamInURL('customPriceId')}`
                                    }
                                    navigate(correctRed)
                                } else {
                                    navigate('/gettingStarted')
                                }
                                setfinishSetup(false)
                            }}
                        />
                    }
                />
            </Routes>
        )
    }

    if (loading) {
        return <Loader loading={true} />
    }

    return <>{children}</>
}

export default AuthScreensWrapper
